<template>
  <div class="vest">
    <van-tabs v-model="active" @change="changeTab" swipeable animated line-width="0" color="#000" background="transparent" title-active-color="#FFF" title-inactive-color="#000">
      <van-tab title-class="vest-tab" v-for="(item, index) in list" :key="item.type" :title="item.text">
        <div class="product-list">
          <div v-for="product in productList[index]" :key="product.productId">
            <img class="product-img" :src="product.commodityPicture[0]" />
            <p class="name">{{ product.tradeName }}</p>
            <p class="intro">{{ product.productIntroduction }}</p>
            <p class="price">Rp{{ product.productPrice }}</p>
            <img @click="addCart(product)" class="cart-icon" src="@/assets/img/cart-icon.png" />
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Tab, Tabs } from 'vant'
import request from '@/utils/request'

Vue.use(Toast)
Vue.use(Tab)
Vue.use(Tabs)

const list = [
  {
    type: 1,
    text: 'pakaian'
  },
  {
    type: 2,
    text: 'sepatu'
  },
  {
    type: 3,
    text: 'Tas'
  },
  {
    type: 4,
    text: 'produk elektronik'
  }
]

export default {
  name: 'Vest',
  data () {
    return {
      list,
      active: 0,
      productList: []
    }
  },
  created () {
    this.getProduct(0)
  },
  methods: {
    getProduct (index) {
      const postData = {
        searchType: 1,
        productCategorytype: this.list[index].type
      }
      request.post('webpublishProductlist', postData).then(res => {
        this.$set(this.productList, index, res.list)
      })
    },
    changeTab (index) {
      this.getProduct(index)
    },
    addCart (product) {
      const postData = {
        productId: product.productId
      }
      request.post('webaddShoppingCart', postData).then(res => {
        Toast('Belanja cart sukses')
      })
    }
  }
}
</script>

<style scoped lang="less">
.vest {
  padding: 68px 50px 104px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: @body-bg-color;
}

.product-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  & > div {
    position: relative;
    width: 310px;
    border-radius: 10px;
    overflow: hidden;

    &:nth-child(n+3) {
      margin-top: 48px;
    }

    .product-img {
      width: 100%;
      height: 340px;
    }

    p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    .name {
      margin-top: 22px;
      font-size: 28px;
      color: #000;
    }

    .intro {
      margin-top: 8px;
      font-size: 22px;
      color: #666;
    }

    .price {
      margin-top: 8px;
      font-size: 28px;
      color: #000;
    }

    .cart-icon {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 26px;
      top: 26px;
    }
  }
}

/deep/ .vest-tab {
  width: 160px;
  height: 60px;
  line-height: 60px;
  border-radius: 100px;
  border: 2px solid #CCC;
  box-sizing: border-box;
  font-size: 26px;

  &.van-tab--active {
    border: none;
    background-color: #000;
  }

  &:nth-child(n+2) {
    margin-left: 20px;
  }
}
</style>
